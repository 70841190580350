.MainWorkExperienceContainer{
    display: flex;
    flex-direction:column ;
    align-items: center;
    gap: 2rem;
}
.ExperienceContainer{
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 20vh;
    border-radius: 50px;
    box-shadow: rgba(255, 255, 255, 0.24) 0px 3px 8px;
}
.Top{
    display: table;
    padding: 1rem 1rem 0;
    margin-bottom: 0.5em;

}
.Top > div,.Top>h3{
    display: table-cell;
}
.Top>h3{
    text-align: left;
    vertical-align: middle;
    font-size: 1.5rem;

}
.Location-Date{
    text-align: right;
    vertical-align: bottom;
    font-size: 0.8rem;
    opacity: 0.8;
}
.row{
    border-top: 1px solid var(--blueish-white);
    padding-top: 10px;}
.Logo{
    vertical-align: middle;
    object-fit: contain;
    height: 3rem;
    margin: 0 1rem;
}
.Description{
    font-weight: 600;
    font-size: 1.7rem;
    text-align: center;

}
.BulletPoints{
    font-size: 1.2rem;
    text-justify: inter-word;
}
