.Navbar {
  color: var(--text-color);
  background-color: var(--background-color);
  display: flex;
  flex-direction: row;
  padding-left: 5%;
  padding-right: 5%;
  border-bottom: 2px solid;
  border-color: var(--primary-color);
  padding-top: 10px;
  padding-bottom: 10px;
  /* to make the navbar stick on top */
  position: sticky;
  top: 0;
  z-index: 1;
}

@media (max-width: 600px) {
  .Navbar {
    display: none;
  }
  .NavLeft {
    display: none;
  }
  .NavRight {
    margin-right: 0;
  }
  .NavElement {
    margin: 10px;
  }
}

.NavLeft {
  flex: auto;
  font-size: large;
  font-weight: 600;
  text-align: left;
}

.NavMiddle {
  flex: 2 auto;
}

.NavRight {
  margin-right: 0.1;
  display: flex;
  align-items: center;
  justify-content: space-around;
  list-style-type: none;
  padding: 0;
  margin: 10px;
  flex: auto;
  font-size: large;
  font-weight: 500;
  /* background-color: red; */
}
.NavElement {
  flex: auto;
  display: inline;
  color: var(--text-color);
  text-decoration: none;
  text-align: center;

  /* background-color: yellow; */
}

.NavElement:hover {
  color: var(--primary-color);
  transform: scale(1.1);
  transition: 100ms ease-in-out;
}
