.ProjectsContainer{
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        gap: 5%;    
}
@media (max-width: 1200px) {
    .ProjectsContainer{
        grid-template-columns: 1fr 1fr;
    }
}
@media (max-width: 700px) {
    .ProjectsContainer{
        grid-template-columns: 1fr;
    }
}
    
.ProjectCard{
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-top: 100%;
    position: relative;
    border-radius: 15%;
    box-shadow: rgba(255, 255, 255, 0.24) 0px 3px 8px;
    background-size: cover;

}

.ProjectCard:hover{   

    transform: scale(1.05);

    transition: 100ms ease-in-out; 

    box-shadow: 0 0 0.5rem 0.2rem var(--primary-color);
    cursor: pointer;
  
}
.ProjectCard_bottom{
    position: absolute;
    bottom: 0;
    padding: 0 1.2em 1.2em 1.2em;
}
.ProjectCard_item{
    border-radius: 2rem;
    border: 1px solid white;
}
.ProjectCard_bottom_container{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
