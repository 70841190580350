.AboutText{
    font-size: 1.5rem;
    line-height: 3rem; 
    font-weight: 500;
    letter-spacing: 0em;
    text-align: left;
}
@media (max-width: 800px) {
    .AboutText {
        font-size: 1rem;
        line-height: 2.5rem; 
    }
}