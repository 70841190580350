.button-main-container {
  display: flex;
  padding: 0px 2rem;
  border-radius: 60px;
  border: 4px solid #EAECEE;
  background: #33ABA0;
  text-decoration: none;
  margin: 10px;
  justify-content: center;
  align-items: center;

}

.button-text {

    color: #FFF;
    font-size: 1.8rem;
    font-style: normal;
    font-weight: 500;
    text-decoration: none;

 }

 @media (max-width: 800px) {
  .button-main-container {
    padding: 0px 1rem;
    border-radius: 30px;
    border: 2px solid #EAECEE;
    background: #33ABA0;
    text-decoration: none;
    margin: 10px;
    justify-content: center;
    align-items: center;
  
  }
  .button-text {
  
      color: #FFF;
      font-size: 1.2rem;
      font-style: normal;
      font-weight: 500;
      text-decoration: none;
  
   }
  
 }