.tech-svg{
    min-height: 4rem;
    min-width: 4rem;
  }

.tech-svg-inner {
    fill: var(--blueish-white);
  }
.tech-svg-outer {
    fill: var(--primary-color);
  }