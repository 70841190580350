@import url("https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700,900");
body,
html {
  margin: 0;
  font-family: "Poppins", Roboto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--background-color);
  color: var(--text-color);
}
.FullPage {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
a {
  color: inherit;
}
/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Poppins','Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */
