.techstack-container{
    display: flex;
    align-items: center;
    justify-content: center;
}
.techstack-main-container{
    display: flex;
    flex-direction: row;
    border-radius: 30px 100px 100px 30px;
    height: 5rem;
    max-width: 80vw;
    align-self: center;
    text-decoration: none;
}
.techstack-title-container{
    flex: initial;
    display: flex;
    border-radius: 30px 0px 0px 30px;
    box-shadow: rgba(255, 255, 255, 0.24) 0px 3px 8px;
    border: 1px solid #EAECEE;
    background: #283849;
    }


@keyframes scroll {
	0% { transform: translateX(0); }
	100% { transform: translateX(calc(-6rem*12))}
}



.techstack-icon-container {
    display: flex;
    box-shadow: rgba(255, 255, 255, 0.24) 0px 3px 8px;
    flex: auto;
    border-radius: 0px 100px 100px 0px;
    border: 1px solid #EAECEE;
    overflow: hidden;
    background: var(--foundation-blue-blue-600, #283849);
    max-width: 60vw;
}


.slide-track {
    gap: 2rem;
    animation: scroll 10s linear infinite;
    display: flex;
}
.techstack-main-container:hover
{
        transform: scale(1.1);
        transition: 100ms ease-in-out; 
        box-shadow: 0 0 0.5rem 0.2rem var(--primary-color);
      }