.ButtonList {
  display: flex;
  flex-direction: row;
  gap: 1.5rem;
  height: 3rem;
  width: fit-content;
  flex-wrap: wrap;
  padding-left: 0;
}

.GradientBackground {
  background: linear-gradient(
      160deg,
      var(--primary-color) 25%,
      var(--secondary-color)
    )
    75%;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-top: 10rem;
  margin-bottom: 5rem;
}

h1 {
  font-size: 5rem;
  font-weight: 700;
  margin: 0;
  padding: 0;
}
.Text {
  font-size: 2rem;
  font-weight: 700;
  padding-left: 0.5rem;
}
@media (max-width: 950px) {
  h1 {
    font-size: 4rem;
  }
  .Text {
    font-size: 2rem;
    padding-left: 0;
  }
}
