:root {
  --primary-color: #3ac3b8;
  --secondary-color: #dceeff;
  --background-color: #283849;
  --dark-background-color: #1F2C39;
  --text-color:#FFFFFF;
  --blueish-white:#dceeff;
}
html {
  scroll-behavior: smooth;
}
.App {
  margin-left: calc(100%/6);
  margin-right: calc(100%/6);
}





