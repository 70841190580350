.footer {
  background-color: var(--dark-background-color);
  margin-top: 3em;
  padding: 1em;
  align-items: center;
}
.footer ul{
    list-style: none;
    padding: 1em 0em;
    margin: 0;
    display: flex;
    justify-content: center;    }
.footer li{
    font-size: 1.2rem;
    padding: 0 1em;

    font-weight: 700;
}

.footer a {
    color: var(--blueish-white);
    text-decoration: none;
}
.copyright{
    padding-top: 1em;
    color: var(--secondary-color);
    text-align: center;
}
.NavElement {
    flex: auto;
    display: inline;
    color: var(--text-color);
    text-decoration: none;
    text-align: center;
}

.footer a:hover {
    color: var(--primary-color);
    transition: 100ms ease-in-out; 
}
